import React, { useState, useEffect } from 'react';

import { FieldSelectInput, FieldTextInput } from '../../components';
import { countries, lada, states } from '../../utils/countries';
import Styles from './CheckoutForm.module.css';
import Error from '../../containers/Signup/Signup.module.css';

import { useAuth } from '../../contexts/AuthContext';

const CheckoutForm = (props) => {

    const {
        order,
        purchase,
        disabled,
        setDisabled,
        onSubmit,
    } = props;

    const [isOtherSelected, setIsOtherSelected] = useState(false);

    const { authUser } = useAuth();

    const [values, setValues] = useState({
        email: '',
        name: '',
        lastName: '',
        country: '',
        state: '',
        phoneCode: '',
        phone: '',
        source: '',
        terms: false,
    });

    const [errors, setErrors] = useState({});

    const getPhoneCode = (country) => {
        const selectedCountry = lada.find(item => item.country === country);
        return selectedCountry ? selectedCountry.value : '';
    };

    useEffect(() => {
        if (authUser) {
            setValues((prevValues) => ({
                ...prevValues,
                email: authUser?.email || '',
                name: authUser?.name || '',
                lastName: authUser?.lastName || '',
                phoneCode: authUser?.phoneCode || '',
            }));
        }
    }, [authUser]);

    const validateForm = () => {
        const newErrors = {};

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{7,15}$/;


        if (!values.name?.trim()) {
            newErrors.name = 'El nombre es requerido';
        }
        if (!values.lastName?.trim()) {
            newErrors.lastName = 'El apellido es requerido';
        }
        if (!values.email?.trim()) {
            newErrors.email = 'El correo es requerido';
        } else if (!emailRegex.test(values.email)) {
            newErrors.email = 'El formato del correo es incorrecto';
        }
        if (!values.country?.trim()) {
            newErrors.country = 'El país es requerido';
        }
        if (!values.state?.trim()) {
            newErrors.state = 'El estado es requerido';
        }
        if (!values.phone?.trim()) {
            newErrors.phone = 'El teléfono es requerido';
        } else if (!phoneRegex.test(values.phone)) {
            newErrors.phone = 'El formato del teléfono es incorrecto';
        }
        if (!values.terms) {
            newErrors.terms = 'Debes aceptar los términos y condiciones';
        }
        if (!values.source?.trim()) {
            newErrors.source = 'Debes seleccionar una opción';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit(values);
        }
    };

    return (
        <form className={Styles.form}>
            <div className={Styles.column}>
                <FieldTextInput
                    label="Correo"
                    name="email"
                    type="text"
                    value={values.email}
                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                />
                {errors.email && <p className={Error.errorMsg}>{errors.email}</p>}
            </div>


            <div className={Styles.row}>
                <div className={Styles.column}>
                    <FieldTextInput
                        label="Nombre"
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={(e) => setValues({ ...values, name: e.target.value })}
                    />
                    {errors.name && <p className={Error.errorMsg}>{errors.name}</p>}
                </div>
                <div className={Styles.column}>
                    <FieldTextInput
                        label="Apellido"
                        name="lastName"
                        type="text"
                        value={values.lastName}
                        onChange={(e) => setValues({ ...values, lastName: e.target.value })}
                    />
                    {errors.lastName && <p className={Error.errorMsg}>{errors.lastName}</p>}
                </div>


            </div>

            <div className={Styles.row}>
                <div className={Styles.column}>
                    <FieldSelectInput
                        label="Pais"
                        name="country"
                        options={
                            [{ value: '', label: 'Selecciona un país', hidden: true }, ...countries]
                        }
                        onChange={(e) => {
                            const selectedCountry = e.target.value;
                            setValues({
                                ...values,
                                country: selectedCountry,
                                state: '',
                                phoneCode: getPhoneCode(selectedCountry),
                            });
                        }}
                    />
                    {errors.state && <p className={Error.errorMsg}>{errors.state}</p>}
                </div>

                <div className={Styles.column}>
                    <FieldSelectInput
                        label="Estado"
                        name="state"
                        options={[
                            { value: '', label: 'Selecciona un estado', hidden: true },
                            ...(Array.isArray(states[values.country]) ? states[values.country] : [])
                        ]}
                        onChange={(e) => setValues({ ...values, state: e.target.value })}
                    />
                    {errors.country && <p className={Error.errorMsg}>{errors.country}</p>}
                </div>
            </div>

            <div className={Styles.row}>
                <div className={Styles.column}>
                    <FieldTextInput
                        label="Lada"
                        name="phoneCode"
                        type="text"
                        value={values.phoneCode}
                        className={Styles.phoneCode}
                        readOnly
                    />
                </div>
                <div className={Styles.column}>
                    <FieldTextInput
                        label="Telefono"
                        name="phone"
                        type="number"
                        className={Styles.phoneNumber}
                        onChange={(e) => setValues({ ...values, phone: e.target.value })}
                    />
                    {errors.phone && <p className={Error.errorMsg}>{errors.phone}</p>}
                </div>
                <div className={Styles.column}>
                    <FieldSelectInput
                        label="¿Por dónde te enteraste de nosotros?"
                        name="source"
                        options={[
                            { value: '', label: 'Selecciona una opción', hidden: true },
                            { value: 'Un amigo, compañero o familiar', label: 'Un amigo, compañero o familiar' },
                            { value: 'Por invitación de la empresa en la que laboro', label: 'Por invitación de la empresa en la que laboro' },
                            { value: 'EXATECMI', label: 'EXATECMI' },
                            { value: 'Acnur', label: 'Acnur' },
                            { value: 'Digital NAO', label: 'Digital NAO' },
                            { value: 'Escuela de Coaches y Mentores', label: 'Escuela de Coaches y Mentores' },
                            { value: 'otro', label: 'Otro' }, 
                        ]}
                        onChange={(e) => {
                            setValues({ ...values, source: e.target.value });
                            setIsOtherSelected(e.target.value === 'otro');
                        }}
                    />
                     {errors.source && <p className={Error.errorMsg}>{errors.source}</p>}
                </div>
            </div>

            <div className={Styles.row}>
                <div className={Styles.column}>
                    {isOtherSelected && (
                        <FieldTextInput
                            label="Especifica otro medio"
                            name="otherSource"
                            type="text"
                            value={values.otherSource || ''}
                            onChange={(e) => setValues({ ...values, otherSource: e.target.value })}
                        />
                    )}
                </div>
            </div>

            <div className={Styles.checkboxInput}>
                <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    checked={values.terms}
                    onChange={(e) => {
                        setValues({ ...values, terms: e.target.checked });
                        setDisabled(!e.target.checked);
                    }}
                />
                <label htmlFor="terms">
                    Acepto los{" "}
                    <a href="/terminos" className={Styles.link}>
                        términos y condiciones
                    </a>
                </label>
            </div>




            <button
                className={Styles.button}
                onClick={handleSubmit}
                disabled={!values.terms}
                type="button"
            >
                Pagar
            </button>

        </form>
    );
}

export default CheckoutForm;
