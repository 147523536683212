import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext'
import { getPurchaseByUser } from '../../models/purchases'
import Button from '../../components/Button/Button'
import Cookies from 'js-cookie'

import pivoteaLogo from '../../assets/PivoteaLogoSecundario.png'
import css from './CheckoutConfirmation.module.css'

const CheckoutConfirmation = () => {
  const navigate = useNavigate()

  const price = process.env.REACT_APP_PIVOTEA_PRICE
  const priceFloat = parseFloat(price.replace('$', '').replace(',', ''))
  const opciones = { style: 'currency', currency: 'MXN' }

  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn, verifyPurchase } = useAuth()
  const [purchase, setPurchase] = useState({})

  const getPurchase = async (id) => {
    await getPurchaseByUser(id)
      .then((response) => {
        setPurchase(response.data.purchase[response.data.purchase.length - 1])
      })
      .catch((error) => {
          console.error('Error processing the payment:', error);
      })
  }

  useEffect(() => {
    const storedUser = Cookies.get('authUser')
    if (storedUser) {
      const user = JSON.parse(storedUser)
      if (user && user.token) {
        setAuthUser(user)
        setIsLoggedIn(true)
      } else {
        setAuthUser(null)
        setIsLoggedIn(false)
        navigate('/login')
      }
    } else {
      navigate('/login')
    }
  }, [setAuthUser, setIsLoggedIn, navigate])

  useEffect(() => {
    if (isLoggedIn && authUser?.id) {
      verifyPurchase()
      getPurchase(authUser?.id)
    }
  }, [isLoggedIn, authUser?.id, verifyPurchase])

  return (
    <div className={css.container}>
      {(purchase?.status === 'Pagado' || purchase?.status === 'msi') && (
        <div className={css.left}>
          <img src={pivoteaLogo} alt="Pivotea Logo" className={css.logo} />
          <h1 className="pivoteawelcome">¡BIENVENIDO A PIVOTEA {authUser?.name.toUpperCase()}!</h1>

          <p>
            Estamos emocionados de tenerte a bordo en este viaje de crecimiento profesional. Gracias
            por completar tu inscripción. Prepárate para explorar nuevas oportunidades, adquirir
            habilidades valiosas y conectar con una comunidad apasionada.
          </p>

          <p>
            ¡Estamos aquí para apoyarte en cada paso y ayudarte a alcanzar tus metas profesionales!
          </p>

          <Button
            size="lg"
            text="Entrar a PIVOTEA"
            type="filled"
            corners="rounded"
            onClick={() => navigate('/')}
          />
          <p className={css.subtitle}>
            Puedes comunicarte con nosotros a través de{' '}
            <a href="mailto:competencias@servicios.tecmilenio.mx" className={css.link}>
              competencias@servicios.tecmilenio.mx.
            </a>
            <br />
            Agradecemos tu confianza en Pivotea y estamos comprometidos a ofrecerte la mejor
            experiencia posible.
          </p>
        </div>
      )}

      {purchase?.status === 'processing' && (
        <div className={css.paymentContainer}>
          <img src={pivoteaLogo} alt="Pivotea Logo" className={css.logo} />
          <h1 className={css.pivoteawelcome}>¡Pago en proceso!</h1>

          <p className={css.description}>Realiza tu pago vía depósito bancario a través de Oxxo.</p>

          <div className={css.platformGrid}>
            <div className={css.platform}>
              <h4>Instrucciones</h4>
              <p className={css.description}>
                * Oxxo cobrará una comisión adicional al momento de realizar el pago.
              </p>
              <ol>
                <li>Indica al cajero que deseas realizar pago de Oxxo Pay.</li>
                <li>Díctale el número de referencia.</li>
                <li>Conserva tu comprobante de pago para cualquier aclaración.</li>
              </ol>
            </div>
            <div className={css.platform}>
              <h4>Notas</h4>
              <ol>
                <li>Oxxo tiene un límite de cobro en caja hasta por $10,000.00.</li>
                <li>Oxxo cobrará una comisión por recibir el pago.</li>
                <li>Pagos solo en efectivo.</li>
              </ol>
            </div>
          </div>
        </div>
      )}

      {purchase?.status === 'canceled' && (
        <div className={css.container}>
          <div className={css.left}>
            <img src={pivoteaLogo} alt="Pivotea Logo" className={css.logo} />
            <h1 className="pivoteawelcome">Transacción no completada</h1>
            <p>
              Parece que no se ha completado ninguna transacción asociada a tu cuenta. Por favor,
              completa una compra antes de continuar.
            </p>
            <Button
              size="lg"
              text="Ir a Checkout"
              type="filled"
              corners="rounded"
              onClick={() => navigate('/checkout')}
            />
          </div>
        </div>
      )}

      {purchase?.status === 'failed' && (
        <div className={css.container}>
          <div className={css.left}>
            <img src={pivoteaLogo} alt="Pivotea Logo" className={css.logo} />
            <h1 className="pivoteawelcome">Transacción no completada</h1>
            <p>
              Parece que no se ha completado ninguna transacción asociada a tu cuenta. Por favor,
              completa una compra antes de continuar.
            </p>
            <Button
              size="lg"
              text="Ir a Checkout"
              type="filled"
              corners="rounded"
              onClick={() => navigate('/checkout')}
            />
          </div>
        </div>
      )}

      {purchase?.status === 'started' && (
        <div className={css.container}>
          <div className={css.left}>
            <img src={pivoteaLogo} alt="Pivotea Logo" className={css.logo} />
            <h1 className="pivoteawelcome">Transacción en curso</h1>
            <p>
              Tienes actualmente una transacción en curso. Completa tus datos de transacción dentro
              de la sección de checkout.
            </p>
            <Button
              size="lg"
              text="Ir a Checkout"
              type="filled"
              corners="rounded"
              onClick={() => navigate('/checkout')}
            />
          </div>
        </div>
      )}

      <div className={css.divider}></div>

      <div className={css.right}>
        <h2>Resumen de tu compra</h2>
        <h4>Pivotea</h4>

        <div className={css.row}>
          <span>Total: </span>
          <span>${purchase?.total}.00 MXN</span>
        </div>

        {purchase?.discountamount && (
          <div className={css.row}>
            <span>Descuento: </span>
            <span>${purchase?.discountamount}.00 MXN</span>
          </div>
        )}

        {purchase?.promo_code && (
          <div className={css.row}>
            <span>Cupón usado: </span>
            <span>{purchase?.promo_code}</span>
          </div>
        )}

        <div className={css.row}>
          <span>Tipo de pago: </span>
          {purchase?.promo_code ?
            <span>Codigo</span> : 
            <span>Tarjeta</span>
          }
          
        </div>

        <h4>Datos del usuario</h4>

        <div className={css.row}>
          <span>Nombre: </span>
          <span>{purchase?.name}</span>
        </div>

        <div className={css.row}>
          <span>Correo: </span>
          <span>{purchase?.email}</span>
        </div>

        <div className={css.row}>
          <span>Teléfono: </span>
          <span>{purchase?.phone}</span>
        </div>

        <div className={css.row}>
          <span>País: </span>
          <span>{purchase?.country}</span>
        </div>

        <div className={css.row}>
          <span>Estado: </span>
          <span>{purchase?.state}</span>
        </div>
      </div>
    </div>
  )
}

export default CheckoutConfirmation
