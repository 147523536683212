import "./NavBar.css";
import logo from "../../assets/pivotea-logo.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import { useState, useEffect } from "react";

import Cookies from "js-cookie";

import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function NavBar(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn, hasPurchase, verifyPurchase } = useAuth();
  const [hidden, setHidden] = useState("hidden");

  const onOpen = () => {
    setHidden("");
  };

  const onClose = () => {
    setHidden("hidden");
  };

  const onLogout = () => {
    Cookies.remove("authUser");
    setIsLoggedIn(false);
    setAuthUser(null);
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isLoggedIn && authUser) {
      verifyPurchase();
    }
  }, [isLoggedIn, authUser, verifyPurchase]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="navbar">
      <a href="/">
        <img src={logo} className="logo" alt="Pivotea" />
      </a>
      <div className="menu-items">
        {isLoggedIn ? (
          <>
            <nav>
              <ul>
                {hasPurchase && (
                  <>
                    <li>
                      <a href="/miespacio">Mi espacio</a>
                    </li>
                    <li>
                      <a href="/gym-profesional">Gym Profesional</a>
                    </li>
                  </>
                )}
                {!hasPurchase && (
                  <li>
                    <a href="/checkout">¡Comprar experiencia!</a>
                  </li>
                )}
              </ul>
            </nav>
            <a aria-describedby={id} onClick={handleClick}>
              <Avatar
                sx={{
                  bgcolor: "var(--secondary-dark)",
                  color: "var(--primary)",
                  cursor: "pointer"
                }}
                alt={authUser?.name || "-"}
              >
                {authUser?.name ? authUser.name[0] : "-"}
              </Avatar>
            </a>
          </>
        ) : (
          <>
            <a className="nav-link" href="/login">Iniciar sesión</a>
            <a className="nav-link" href="/signup">Registro</a>
          </>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {hasPurchase && (
            <>
              <a href="/miespacio" className="logout-mobile" style={{ padding: "0.5rem", cursor: "pointer" }}>
                Mi espacio de trabajo
              </a>
              <a href="/diario-digital" className="logout-mobile" style={{ padding: "0.5rem", cursor: "pointer" }}>
                Mi diario digital
              </a>
            </>
          )}
          {isLoggedIn && authUser?.current_module === "ffc340f4-eea3-4ab5-8430-d65fb062a6e8" && (
            <a href="/proyecto-de-aprendizaje" className="logout-mobile" style={{ padding: "0.5rem", cursor: "pointer" }}>
              Proyecto de aprendizaje
            </a>
          )}
          <a className="logout-mobile" style={{ padding: "0.5rem", cursor: "pointer" }} onClick={onLogout}>
            <ExitToAppRoundedIcon /> Cerrar sesión
          </a>
        </Popover>
      </div>
      <div className="mobile-toggle">
        <button onClick={onOpen}>
          <MenuRoundedIcon />
        </button>
      </div>
      <div className={`menu-overlay ${hidden}`} onClick={onClose}></div>
      <div className={`mobile-menu ${hidden}`}>
        <button onClick={onClose}>
          <CloseRoundedIcon />
        </button>
        <nav>
          <ul>
            {hasPurchase ? (
              <>
                <li>
                  <a href="/miespacio">Mi espacio</a>
                </li>
                <li>
                  <a href="/gym-profesional">Gym Profesional</a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a href="/login">Iniciar Sesión</a>
                </li>
                <li>
                  <a href="/signup">Registrarme</a>
                </li>

              </>

            )}
          </ul>
        </nav>
        <div className="mobile-profile">
          <Avatar
            sx={{ bgcolor: "var(--secondary-dark)", color: "var(--primary)" }}
            alt={isLoggedIn ? authUser?.name : "-"}
          >
            {authUser?.name ? authUser.name[0] : "P"}
          </Avatar>
          <p>{authUser?.name || "¡Se Parte de Pivotea!"}</p>
        </div>
        {isLoggedIn &&
          <a className="logout-mobile" onClick={onLogout}>
            <ExitToAppRoundedIcon /> Cerrar sesión
          </a>
        }

      </div>
    </div>
  );
}
