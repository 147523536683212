import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { clarity } from 'react-microsoft-clarity'

import './index.css'
import {
  Home,
  Vocacionamiento,
  Login,
  Signup,
  Privacidad,
  GymPage,
  LandingPage,
  DigitalDiary,
  ProyectoDeAprendizaje,
  Escenarios,
  Recovery,
  Error,
  CheckoutPage,
} from './containers'
import reportWebVitals from './reportWebVitals'
import { NavBar } from './components'
import { AuthProvider } from './contexts/AuthContext'
import Footer from './components/Footer/Footer'
import LandingPageComercial from './containers/LandingPageComercial/LandingPageComercial'
import LandingPageComercial2 from './containers/LandingPageComercial2/LandingPageComercial2'
import CheckoutConfirmation from './containers/CheckoutConfirmationPage/CheckoutConfirmation'
import TermsAndConditions from './containers/Terms/Terms'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <React.Fragment>
        <NavBar />
        <LandingPage />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/checkout',
    element: (
      <React.Fragment>
        <NavBar />
        <CheckoutPage />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/checkout/return',
    element: (
      <React.Fragment>
        <NavBar />
        <CheckoutConfirmation />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/miespacio',
    element: (
      <React.Fragment>
        <NavBar />
        <Home />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/vocacionamiento',
    element: (
      <React.Fragment>
        <NavBar />
        <Vocacionamiento />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/reset-password',
    element: <Recovery />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/gym-profesional',
    element: (
      <React.Fragment>
        <NavBar />
        <GymPage />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/diario-digital',
    element: (
      <React.Fragment>
        <NavBar />
        <DigitalDiary />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/proyecto-de-aprendizaje',
    element: (
      <React.Fragment>
        <NavBar />
        <ProyectoDeAprendizaje />
        <Footer />
      </React.Fragment>
    ),
  },
  {
    path: '/escenarios',
    element: (
      <React.Fragment>
        <Escenarios />
      </React.Fragment>
    ),
  },
  {
    path: '/terminos',
    element: (
      <React.Fragment>
        <TermsAndConditions />
      </React.Fragment>
    ),
  },
  {
    path: '/landing-acompañamiento',
    element: (
      <React.Fragment>
        <NavBar/>
          <LandingPageComercial />
        <Footer/>
      </React.Fragment>
    ),
  },
  {
    path: '/landing-vocacionamiento',
    element: (
      <React.Fragment>
        <NavBar/>
          <LandingPageComercial2 />
        <Footer/>
      </React.Fragment>
    ),
  },
  {
    path: '*',
    element: (
      <React.Fragment>
        <NavBar />
        <Error />
        <Footer />
      </React.Fragment>
    ),
  }
])

// Start seeing data on the Clarity dashboard with your id
clarity.init(process.env.REACT_APP_CLARITY_ID)

// Identify the user
clarity.identify('USER_ID', { userProperty: 'value' })

// Cookie consent
clarity.consent()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
