import React, { useState, useEffect, useContext, useCallback } from "react";
import { getPurchaseByUser } from "../models/purchases";

import Cookies from "js-cookie";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider(props) {
  const [authUser, setAuthUserState] = useState(null); 
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasPurchase, setHasPurchase] = useState(false);
  const [checkPurchase, setCheckPurchase] = useState(false);

  const setAuthUser = useCallback((value) => {
    setAuthUserState(value);
    if (value) {
      Cookies.set("authUser", JSON.stringify(value), { expires: 7 }); 
    } else {
      Cookies.remove("authUser"); 
    }
  }, []);

  const verifyPurchase = useCallback(async () => {
    if (isLoggedIn && authUser) {
      try {
        const response = await getPurchaseByUser(authUser?.id);
        const lastPurchase = response?.data.purchase.length > 0 ? response.data.purchase[response.data.purchase.length - 1] : null;
        if (lastPurchase?.status === "Pagado" || !!authUser.current_step) {
          setHasPurchase(true);
        } else {
          setHasPurchase(false);
        }
      } catch (error) {
        console.error("Error al verificar la compra:", error);
      }
    }
  }, [isLoggedIn, authUser]);

  useEffect(() => {
    const storedUser = Cookies.get("authUser");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setAuthUser(user); 
      setIsLoggedIn(true);
      setCheckPurchase(true);
    }
  }, [setAuthUser]);

  useEffect(() => {
    if (isLoggedIn && authUser && checkPurchase) {
      verifyPurchase();
    }
  }, [isLoggedIn, authUser, checkPurchase, verifyPurchase]);

  const value = {
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn,
    hasPurchase,
    setHasPurchase,
    verifyPurchase
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
